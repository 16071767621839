<template>
  <div class="pick-seats">
    <map-legend />
    <v-progress-linear
      :background-opacity="loadingMap ? 0.2 : 0"
      :indeterminate="loadingMap"
      height="1"
    />
    <v-divider />
    <div class="map-panel">
      <div class="map-container">
        <event-map v-if="eventMap" />
      </div>
      <map-controls @seats-selected="selected" />
    </div>
  </div>
</template>

<script lang="ts">
import EventMap from "./EventMap.vue";
import MapLegend from "./MapLegend.vue";
import MapControls from "./MapControls.vue";
import { usePickSeatsStore } from "./store";

export default defineComponent({
  components: {
    EventMap,
    MapLegend,
    MapControls,
  },
  props: {
    eventMap: { type: Object, required: true },
    availableSeats: { type: Array as PropType<string[]>, required: true },
    currentSeats: { type: Array as PropType<string[]>, default: () => [] },
    loadingMap: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
    maxSeats: { type: Number, default: 10 },
  },
  setup(props) {
    const store = usePickSeatsStore();
    const { selection, loading } = storeToRefs(store);

    store.initStore(
      props.eventMap,
      props.currentSeats,
      props.maxSeats,
      props.availableSeats
    );

    return {
      selection,
      loading,
    };
  },
  methods: {
    selected() {
      this.$emit("seats-selected", this.selection);
    },
  },
  watch: {
    loadingMap(value) {
      this.loading = value || this.saving;
    },
    saving(value) {
      this.loading = value || this.loadingMap;
    },
  },
});
</script>

<style lang="scss" scoped>
.pick-seats {
  .map-panel {
    height: calc(100% - 110px);
  }
  .map-container {
    height: 100%;
  }

  @media only screen and (max-width: 767px) {
    padding-bottom: 100px;
  }

  @media only screen and (min-width: 768px) {
    .event-details {
      max-width: 800px;
      padding-top: 50px;
    }
  }

  .image-container {
    border-radius: 10px;
    overflow: hidden;
  }
}
</style>
