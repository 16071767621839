<template>
  <div class="map-legend h-35px text-caption mb-2">
    <div class="map-legend-item map-legend-item__selection">
      <div class="icon">
        <v-icon color="white">check</v-icon>
      </div>
      <span>Selection</span>
    </div>
    <div class="map-legend-item map-legend-item__available">
      <div class="icon"></div>
      <span>Available</span>
    </div>
    <div class="map-legend-item map-legend-item__unavailable">
      <div class="icon"></div>
      <span>Unavailable</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.map-legend {
  display: flex;
  justify-content: center;
  .map-legend-item {
    display: flex;
    align-items: center;
    margin: 0 10px;

    .icon {
      content: " ";
      height: 20px;
      width: 20px;
      border-radius: 5px;
      margin-right: 5px;
    }
    &__selection .icon {
      background: var(--v-primary-base);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      > * {
        font-size: 1rem;
      }
    }
    &__available .icon {
      background: #b4b4b4;
    }
    &__unavailable .icon {
      background: #616161;
    }
  }
}
</style>
