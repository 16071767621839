<template>
  <v-sheet class="map-controls px-4 py-2 md:py-4">
    <div class="checkout-btn-container">
      <v-btn
        @click="selected"
        :disabled="!isSelected"
        :loading="loading"
        size="large"
        color="primary"
        block
        >{{ action }}</v-btn
      >
    </div>
  </v-sheet>
</template>

<script lang="ts">
import { usePickSeatsStore } from "./store";

export default defineComponent({
  setup() {
    const store = usePickSeatsStore();
    const { isSelected, minSeats, maxSeats, selection, loading } =
      storeToRefs(store);

    const action = computed(() => {
      if (maxSeats.value > 1) {
        const numSelected = selection.value.length;
        if (isSelected.value && maxSeats.value > minSeats.value) {
          return `Save (${numSelected} / ${maxSeats.value} seats)`;
        } else {
          return `Selected ${numSelected} / ${maxSeats.value} seats`;
        }
      }

      return "Save";
    });

    return {
      isSelected,
      action,
      loading,
    };
  },
  methods: {
    selected() {
      this.$emit("seats-selected");
    },
  },
});
</script>

<style lang="scss" scoped>
.map-controls {
  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
    .checkout-btn-container {
      width: 400px;
    }
  }
  @media only screen and (max-width: 767px) {
    border-radius: 30px 30px 0 0;
    // padding: 20px 10px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 2px 4px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
      0px 4px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
      0px 1px 10px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12)) !important;
  }
}
</style>
